<template>
    <section>
        <div class="row mx-0">
            <div class="col-xl-6 col-lg-6 col-md col-sm col px-0 overflow-auto custom-scroll border-right" :class="$route.name == 'tendero.carrito' ? 'alto-carrito' : 'alto'">
                <div class="row mx-0 px-3 pl-5 my-1 border-bottom border-right align-items-center justify-content-center" style="height:40px;">
                    <div class="bg-light-f5 col-11 f-14 br-10 d-middle-center">
                        <p> {{ productos.length }} Productos</p>
                    </div>
                </div>
                <div class="p-2 f-15 overflow-auto custom-scroll chat-mensajes" style="max-height: calc(100% - 55px);">
                    <div
                    v-infinite-scroll="lista_productos"
                    infinite-scroll-disabled="busy"
                    infinite-scroll-immediate-check="false"
                    infinite-scroll-distance="10"
                    >
                        <div class="row mx-0 justify-center">
                            <card-producto
                            v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                            :producto="prod"
                            @detalle="detalle_producto"
                            @change="cambio_cantidad"
                            @agregar="agregar_producto"
                            />
                        </div>
                        <contentLoader v-show="loading" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md col-sm col px-0 alto">
                <productos-carrito ref="productosCarrito" />
            </div>
        </div>
        <modalProductoDesconocido ref="modalProductoDesconocido" />
        <modalDetalleProducto ref="modalDetalleProducto" />
    </section>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import Almacen from "~/services/almacen/almacen"
import {mapGetters} from 'vuex'
export default {
    directives: {infiniteScroll},
    components: {
        cardProducto: () => import('./card-producto'),
        productosCarrito: () => import('./productos-carrito'),
        modalProductoDesconocido: () => import('./modalProductoDesconocido'),
        modalDetalleProducto: () => import('./modalDetalleProducto'),
    },
    props:['categoria'],
    data(){
        return {
            busy: false,
            porcentaje: '',
            verDetalle: 0,
            producto_activo:0,
            info_categoria:{},
            loading:false,
            page:0,
        }
    },
    computed:{
        ...mapGetters({
            productos: 'pedidos/pedidos_tendero/productos',
            query: 'pedidos/pedidos_tendero/query',
        }),
    },
    watch:{
        categoria(val){
            if(val){
                this.page = 0
                this.$store.commit('pedidos/pedidos_tendero/vaciar_productos')
                this.lista_productos()
            }else{
                this.$store.commit('pedidos/pedidos_tendero/vaciar_productos')
            }
        }
    },
    methods: {
        async lista_productos(){
            try {
                if(this.categoria == null){
                    console.log('Por favor seleccione una categoria');
                    return
                }
                this.page = this.page + 1
                this.loading = true
                this.busy = true;
                let params = {
                    categoria: this.categoria,
                    page:this.page,
                    caso:2,
                    query:this.query
                }
                const {data} = await Almacen.listar_productos(params)

                this.$store.commit('pedidos/pedidos_tendero/set_productos', data.productos.data)

                if(data.productos.current_page === data.productos.last_page){
                    this.busy = true;
                }else{
                    this.busy = false;
                }


            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async agregar_producto(id_producto){

            const {data} = await Almacen.agregar_producto(id_producto)
            this.$store.commit('pedidos/pedidos_tendero/alter_agregados',data.producto) // altera el carrito
            this.$store.commit('pedidos/pedidos_tendero/alter_producto',data.producto) // altera el listado
            await this.$store.dispatch('pedidos/pedidos_tendero/get_resumen') // actualiza el resumen
            this.updatePedidoMinimo()


        },
        async cambio_cantidad(obj){
            try {
                if(obj.producto.compra_maxima != 0){
                    if(obj.cantidad > obj.producto.compra_maxima){
                        return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto. Permitido '+obj.producto.compra_maxima+' Unidades','warning');
                    }
                } else {
                    if(obj.cantidad > 99999){
                        return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto.','warning');
                    }
                }
                let model = {
                    cantidad: obj.cantidad,
                    id_producto: obj.producto.id
                }
                const {data} = await Almacen.alter_producto(model)

                if(data.eliminado){
                    let producto = obj.producto
                    producto.en_pedido = false
                    // console.log(producto);

                    this.$store.commit('pedidos/pedidos_tendero/alter_lista_productos',producto)
                    this.$store.commit('pedidos/pedidos_tendero/remover_producto',producto.id)
                    await this.$store.dispatch('pedidos/pedidos_tendero/get_resumen')
                    this.updatePedidoMinimo()

                    return

                }

                this.$store.commit('pedidos/pedidos_tendero/alter_agregados',data.producto)
                this.$store.commit('pedidos/pedidos_tendero/alter_producto',data.producto)
                await this.$store.dispatch('pedidos/pedidos_tendero/get_resumen')
                this.updatePedidoMinimo()

            } catch (e){
                this.error_catch(e)
            }
        },
        updatePedidoMinimo(){
            this.$refs.productosCarrito.updatePedidoMinimo()
        },
        modal_desconocido(){
            this.$refs.modalProductoDesconocido.toggle()
        },

        detalle_producto(producto){
            this.$refs.modalDetalleProducto.datos_cambio(producto)
        },


    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 104px);
}
.alto-carrito{
    height: calc(100vh - 104px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}

@media(max-width:980px){
    .alto-carrito{
        height: calc(100vh - 113px) !important;
    }
    .alto{
        height: calc(100vh - 120px) !important;
    }
}
</style>
